<template>
  <div class="add">
    <a-spin :spinning="spinning" style="margin-top: 100px;">
      <div class="add-tab">
        <div class="tab-left">
          <a-button @click="goOut">返回</a-button>
        </div>
        <div class="title">归档流程设计</div>
        <div class="tab-right" style=" min-width: 120px;">
          <a-button v-if="$route.query.type == 1" @click="saveDraft" :loading="spinning">保存草稿</a-button>
          <a-button @click="validate" type="primary" :loading="spinning" class="tab-right-rel">发布</a-button>
        </div>
      </div>
      <div class="tab-main">
        <flowPath ref="proDesign" class="tabPane" />
      </div>
      <Footer v-if="activeKey === '1'" class="foot"></Footer>
    </a-spin>
    <!-- 校验弹窗 -->
    <a-modal :title="null" :footer="null" v-model:visible="modalVisible" centered>
      <div class="modal">
        <div class="modal-header">
          <ExclamationOutlined class="icon" />
          <span>当前无法发布</span>
          <span class="tip">{{ errorTitle }}</span>
        </div>
        <div class="modal-main">
          <template v-for="(item, index) in errList" :key="item.title"> {{ index + 1 }}: {{ item }} <br /> </template>
        </div>
        <div class="footer">
          <a-button type="primary" @click="goEdit">去修改</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref, createVNode, computed, onMounted } from 'vue'
import Footer from '@/components/Footer/index'
import flowPath from './components/flowPath.vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { cmsNotice } from '@/utils/utils'
import { addRecordsProcess, draftRecordsProcess, changeRecords, getRecordsDetail } from '@/apis/recordsProcess'
const basic = ref()
const proDesign = ref()
const formDesign = ref()
// const basicForm = computed(() => store.state.process.basic)
const activeKey = ref('1')
const router = useRouter()
const $route = useRoute()
const store = useStore()
const loading = ref(false)
const modalVisible = ref(false) // 弹窗
const spinning = ref(false) // 加载动作
const errList = ref([])
const paramslist = ref([])
const errorTitle = ref('')
// 表单信息
const formState = computed(() => store.state.process.design.formItems)
const process = computed(() => store.state.process)

onMounted(async () => {
  store.commit('setIsPreview', false)
  console.log('传参', $route.query.enterpriseId)
  if ($route.query.enterpriseId) {
    const processList = store.state.process
    getRecordsDetail($route.query.enterpriseId).then(res => {
      console.log(res)
      if (res.success) {
        const data = res.data
        processList.design.process = JSON.parse(data.process)
        selectedNode.props.cc = {
          custodianList: data.custodianList,
          recordsName: data.name,
          fileTypeList: data.fileTypeList,
          scopeList: data.scopeList,
          remark: data.remark
        }
      }
    })
  }
})
// })
const selectedNode = () => {
  return this.$store.state.process.selectedNode
}
// 流程设计
const designVal = () => {
  const err = proDesign.value.validate()
  console.log('222流程设计的校验', err)
  return err
}
const validate = async () => {
  spinning.value = true
  console.log('瞅瞅process里面有啥子', process.value.design.process.props.cc)
  errList.value.length = 0
  let err = process.value.design.process.props.cc
  if (err.recordsName == []) {
    errList.value.push('请设置归档流程名称')
    errorTitle.value = '(发起-设置归档基础信息未设置完成)'
  }
  if (err.fileTypeList.length == 0) {
    errList.value.push('请设置文件类型')
    errorTitle.value = '(发起-设置归档基础信息未设置完成)'
  }
  if (err.custodianList.length == 0) {
    errList.value.push('请设置归档流程负责人')
    errorTitle.value = '(发起-设置归档基础信息未设置完成)'
  }
  if (err.scopeList.length == 0) {
    errList.value.push('请选择适用范围')
    errorTitle.value = '(发起-设置归档基础信息未设置完成)'
  }
  if (designVal()) {
    errList.value = errList.value.concat(designVal())
    console.log('errList.value', errList.value)
    errorTitle.value = '(审批人-设置审批人未设置完成)'
  }
  if (errList.value.length > 0) {
    modalVisible.value = true
    spinning.value = false
  } else {
    if ($route.query.enterpriseId) {
      await changeProcess()
    } else {
      await addProcess()
    }
  }
}
const changeProcess = async () => {
  // const pBasic = process.value.basic
  paramslist.value = process.value.design.process.props.cc
  let params = getParams()
  params.processId = $route.query.enterpriseId

  console.log('流程设计', JSON.stringify(process.value.design.process))
  await changeRecords(params).then(res => {
    if (res.success) {
      console.log(res)
      cmsNotice('success', '归档流程修改成功')
      spinning.value = false
      router.back()
      store.state.process.design.formItems = null
      store.state.process.selectFormItem = null
    } else {
      spinning.value = false
    }
  })
  console.log('嘻嘻', process.value.design.process)
}

const addProcess = async () => {
  // const pBasic = process.value.basic
  paramslist.value = process.value.design.process.props.cc
  let params = getParams()
  console.log('流程设计', JSON.stringify(process.value.design.process))
  await addRecordsProcess(params).then(res => {
    if (res.success) {
      console.log(res)
      cmsNotice('success', '归档流程添加成功')
      spinning.value = false
      router.back()
      store.state.process.design.formItems = null
      store.state.process.selectFormItem = null
    } else {
      spinning.value = false
    }
  })
  console.log('哈哈哈哈', process.value.design.process)
}

//保存草稿
const saveDraft = async () => {
  spinning.value = true
  errList.value.length = 0
  paramslist.value = process.value.design.process.props.cc
  // console.log('66666', paramslist.value)
  if (paramslist.value.recordsName == []) {
    errList.value.push('请设置归档流程名称')
  }
  if (errList.value.length > 0) {
    modalVisible.value = true
    spinning.value = false
  } else {
    let params = getParams()
    params.processId = $route.query.enterpriseId
    await draftRecordsProcess(params).then(res => {
      console.log('res', res)
      if (res.success) {
        console.log(res)
        cmsNotice('success', '保存草稿成功')
        spinning.value = false
        router.back()
        store.state.process.design.formItems = null
        store.state.process.selectFormItem = null
      } else {
        spinning.value = false
      }
    })
  }
}
const getParams = () => {
  return {
    process: JSON.stringify(process.value.design.process),
    processMold: 2, // 归档流程
    name: paramslist.value.recordsName, //归档流程名称
    remark: paramslist.value.remark, // 备注
    scopeList: paramslist.value.scopeList, //适用范围
    custodianList: paramslist.value.custodianList.map(function(user) {
      return { staffId: user.dataId }
    }), //负责人
    fileTypeList: paramslist.value.fileTypeList.map(function(item) {
      return item.dataId
    }) // 文件类型
  }
}
// 返回
const goOut = value => {
  Modal.confirm({
    title: '是否返回！',
    width: '400px',
    content: '请确认是否返回',
    centered: true,
    confirmLoading: loading.value,
    icon: createVNode(ExclamationOutlined),
    onOk: () => {
      // 确认返回
      successFun()
    }
  })
}
const successFun = () => {
  router.push('/businessManage/flowManage/archive')
  store.state.process.design.formItems = null
  store.state.process.selectFormItem = null
}
onBeforeRouteLeave(() => {
  Modal.destroyAll()
})
const goEdit = () => {
  modalVisible.value = false
}
</script>

<style scoped lang="scss">
.add {
  background-color: #f0f2f5;
  min-height: 100vh;
  padding-bottom: 12px;

  .foot {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
  }

  .add-tab {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 40px;
    height: 60px;
    background-color: #fff;
    z-index: 10;
    margin: 0px auto;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04);

    .title {
      // width: 120px;
      padding-left: 106px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #333333;
      line-height: 34px;
      text-align: center;
      font-style: normal;
    }

    .ant-tabs-bar {
      border: none;
    }
  }

  :deep(.ant-tabs-tab) {
    color: #999999;
    font-weight: 500;
    font-size: 16px;
    padding: 11px 16px;
  }

  :deep(.ant-tabs-tab-active) {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
  }

  :deep(.ant-tabs-ink-bar) {
    width: 40px !important;
    left: 28px;
  }

  :deep(.ant-tabs-nav-container) {
    z-index: 11;
    position: fixed;
    // margin-left: 300px;
    width: calc(100vw - 500px);
    margin: 0 auto;
  }

  .tab-right-rel {
    margin-left: 12px;
  }

  .ant-tabs {
    overflow: visible;
  }

  .tab-main {
    position: relative;
    padding-top: 20px;
    top: 16px;
    width: calc(100vw - 500px);
    margin: 0px auto;

    .tabPane {
      overflow: auto;
      width: 100vw;
      height: 100vh;
      margin: 0 auto;
      margin-left: -240px;
    }

    .tabPane::-webkit-scrollbar {
      display: flex !important;
    }

    .tab-item {
      .tab-two {
        color: #333333;
        font-weight: 500;
        font-size: 16px;
      }

      .tab-one {
        font-weight: 500;
        color: #999999;
      }
    }

    :deep(.ant-tabs-ink-bar) {
      bottom: 3px;
    }
  }
}

.modal {
  .modal-header,
  .modal-text {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0px;

    .icon {
      background-color: #faad14;
      color: #fff;
      border-radius: 50%;
      padding: 3px 0px;
      width: 21px;
      height: 21px;
      margin-right: 12px;
    }

    .tip {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 22px;
      font-size: 14px;
      margin-left: 8px;
    }

    .tip-text {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 22px;
      font-size: 14px;
    }
  }

  .modal-text {
    margin: 8px 0;
  }

  .modal-main {
    margin-left: 20px;

    .main-item {
      background-color: #f5f6f7;
      border-radius: 2px;
      padding: 24px;
      font-size: 14px;
      margin-bottom: 8px;

      .item-header {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
      }

      .item-content {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        margin-top: 10px;
      }
    }
  }

  .footer {
    text-align: center;
    margin-top: 16px;
  }
}
</style>
